import * as logger from './logger';

function getEmbedIdApiUrl(embedId: string) {
    return `https://${__SCRIPT_EMBED__EMBED_DNS__}/config/${embedId.split('/')[1]}.json`;
}

export function getEmbedConfig(
    embedId: string,
    onSuccess: (config: { enablePoweredByIssuu: boolean }) => void,
    onError: (statusCode: number) => void,
) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', getEmbedIdApiUrl(embedId), true);
    xhr.onreadystatechange = function () {
        try {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    const config = JSON.parse(xhr.responseText);
                    onSuccess(config);
                } else {
                    onError(xhr.status);
                }
            }
        } catch (e) {
            if (e instanceof Error) {
                logger.error(e);
            }
        }
    };
    xhr.send();
}
